(<template>
  <header :class="{'landing-header--mobile-nav': mobileNav}"
          class="landing-header">
    <div class="landing-header__wrapper">
      <router-link :to="$makeRoute({name: 'Landing'})"
                   class="landing-header__logo" />
      <nav class="landing-header__nav">
        <a v-for="link in internalLinks"
           :key="link.link"
           :href="link.link"
           class="landing-header__nav-link">{{ link.text }}</a>
        <button class="landing-header__nav-link"
                @click="goToLink({name: 'SignIn'})">{{ $gettext('Log in') }}</button>
        <button class="sk-btn sk-btn--red landing-header__nav-btn"
                @click="goToLink({name: 'SignUp'})">{{ $gettext('Sign up') }}</button>
        <!--<locale-select class="landing-header__locale-select" />-->
      </nav>
    </div>
    <button class="landing-header__burger-btn"
            @click="toggleMobileNav">
      <span class="landing-header__burger-btn-first-line"></span>
      <span class="landing-header__burger-btn-second-line"></span>
      <span class="landing-header__burger-btn-third-line"></span>
      <span class="landing-header__burger-btn-fourth-line"></span>
    </button>
  </header>
</template>)

<script>
  export default {
    data() {
      return {
        mobileNav: false
      };
    },
    computed: {
      internalLinks() {
        return [
          {
            text: this.$gettext('How it works'),
            link: this.$gettext('https://www.tikktalk.com/en/how-tikktalk-works/')
          },
          {
            text: this.$gettext('For interpreters'),
            link: this.$gettext('https://www.tikktalk.com/en/interpreters/')
          },
          {
            text: this.$gettext('For customers'),
            link: this.$gettext('https://www.tikktalk.com/en/customers/')
          },
          {
            text: this.$gettext('Contact us'),
            link: this.$gettext('https://www.tikktalk.com/en/contact-us/')
          }
        ];
      },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; }
    },
    watch: {
      clientWidth(newVal, oldVal) {
        if (newVal > 1023 && oldVal < 1024) {
          this.mobileNav = false;
          this.$emit('mobilenav', this.mobileNav);
        }
      }
    },
    methods: {
      toggleMobileNav() {
        this.mobileNav = !this.mobileNav;
        this.$emit('mobilenav', this.mobileNav);
      },
      goToLink(routeObj) {
        if (this.mobileNav) this.toggleMobileNav();
        this.$router.push(this.$makeRoute(routeObj));
      }
    }
  };
</script>

<style scoped>
  .landing-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 60px;
    padding: 0 20px;
    background-color: #fff;
  }

  .landing-header__wrapper {
    display: flex;
    justify-content: space-between;
    width: 1140px;
    max-width: 100%;
  }

  .landing-header__logo {
    display: block;
    width: 140px;
    background-image: url(~@assets/imgs/logos/black_logo.svg);
    background-image: var(--image-logo);
    background-position: 50% 50%;
    background-size: auto 25px;
    background-repeat: no-repeat;
  }

  .landing-header__nav {
    display: flex;
  }

  .landing-header__nav-link {
    position: relative;
    display: inline-block;
    padding: 0 10px;
    font-size: 12px;
    line-height: 60px;
    text-align: left;
  }

  .landing-header__nav-link:focus {
    z-index: 1;
    outline: 2px solid #3af;
  }

  .landing-header__nav-link:hover {
    background-color: #f4f6f8;
  }

  .landing-header__nav-link:active {
    background-color: #e8e9ed;
    outline: none;
  }

  .landing-header__nav-btn {
    display: inline-block;
    align-self: center;
    width: auto;
    margin-left: 17px;
    padding: 0 15px;
  }

  .landing-header__burger-btn {
    position: relative;
    display: none;
    flex-shrink: 0;
    width: 45px;
  }

  .landing-header__burger-btn span {
    position: absolute;
    left: 50%;
    width: 25px;
    height: 1px;
    margin-left: -12px;
    background-color: #000;
    transition: all 0.25s ease-in-out;
  }

  .landing-header__burger-btn-first-line {
    top: 13px;
  }

  .landing-header__burger-btn-second-line,
  .landing-header__burger-btn-third-line {
    top: 21px;
  }

  .landing-header__burger-btn-fourth-line {
    top: 29px;
  }

  .landing-header--mobile-nav .landing-header__burger-btn-first-line {
    top: 16px;
    left: 50%;
    width: 0;
  }

  .landing-header--mobile-nav .landing-header__burger-btn-second-line {
    transform: rotate(45deg);
  }

  .landing-header--mobile-nav .landing-header__burger-btn-third-line {
    transform: rotate(-45deg);
  }

  .landing-header--mobile-nav .landing-header__burger-btn-fourth-line {
    top: 16px;
    left: 50%;
    width: 0;
  }

  .landing-header__locale-select {
    display: flex;
    align-items: center;
    width: 130px;
    margin-left: 20px;
    text-align: right;
  }

  @media (max-width: 1024px) {
    .landing-header {
      min-height: 45px;
      padding: 0;
    }

    .landing-header__logo {
      margin-left: 10px;
    }

    .landing-header__nav {
      display: none;
      background-color: #fff;
    }

    .landing-header__nav-link {
      line-height: 45px;
    }

    .landing-header__nav-btn {
      width: calc(100% - 26px);
      margin-right: 13px;
      margin-left: 13px;
    }

    .landing-header--mobile-nav .landing-header__nav {
      position: fixed;
      top: 45px;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
    }

    .landing-header__burger-btn {
      display: inline-block;
    }

    .landing-header__locale-select {
      width: 100%;
      margin: 15px 0 0;
      padding: 0 13px;
    }
  }
</style>
